<template>
	<div class="news-container">
		<!--content-->
		<div class="news-wrapper">
			<!--新闻列表-->
			<div class="news-list">
				<div class="news-info">
					<!--右侧新闻-->
					<div class="news-details-wrapper">
						<div class="tag-box">
							<div class="desc-header">
								<h3 class="padding-reduce">{{descList.cmsTitle}}</h3>
								<p class="desc-time"><i class="fa fa-calendar" style="margin-right:5px;color:#337ab7;"></i>{{descList.createTime|dateFmt('YYYY-MM-DD')}}</p>
								<p class="desc-time"><i class="fa fa-eye" style="margin-right:5px;color:#337ab7;"></i>{{descList.clicks}}</p>
							</div>
							<p class="desc-cms">{{descList.cmsDesc}}</p>
							<!--<p><img :src="this.ImgUrl+descList.cmsIco"></p>-->
							<div v-html="descList.cmsContent" class="desc-content">{{descList.cmsContent}}</div>
							<hr style="border:0;border-top:1px solid #eee;margin:25px 0" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { getList, getInfoNews } from "../../apis/apis.js";
	export default {
		components: {
		},
		data() {
			return {
				ImgUrl: "//service.yzvet.com/haip-file/download?fileUrl=",
				descList: [{			
				}]
			};

		},
		methods: {
			async getInfoNews(id) {
				try {
					let data = await getInfoNews(id);
					if(data.data.code == 1000) {
						this.descList = data.data.data;
						const regex = new RegExp('<img', 'gi')
						this.descList.cmsContent= this.descList.cmsContent.replace(regex, `<img style="max-width: 100%; height: auto"`);
					}
				} catch(err) {}
			},
		},
		mounted() {
			this.cmsId = this.$route.query.cmsId; //获取列表页传递过来的ID
			if(this.cmsId != null && this.cmsId != undefined) {
				this.getInfoNews(this.cmsId);
			};
		},
	}
</script>

<style scoped>
	/*新闻列表*/
	.news-wrapper {
		width: 100%;
		padding-bottom: 40px;
		background: #f8f8f8;
		overflow: hidden;
	}

	.news-list {
		width: 100%;
		background: #F8F8F8;
		margin-bottom: 40px;
	}

	.news-info {
		width: 1200px;
		margin: 0 auto;
		margin-top: 30px;
	}

	.news-left-wrapper {
		width: 25%;
		height: 860px;
		float: left;
	}

	.news-details-wrapper {
		width: 100%;
		background: #fff;
	}
	/*新闻详情*/

	.tag-box {
		padding: 15px 20px;
	}

	.desc-header {
		border-bottom: solid 1px rgba(0, 0, 0, .1);
	}

	.padding-reduce {
		font-size: 20px;
		font-weight: 400;
		color: #337ab7;
		margin-top: 10px;
		line-height: 38px;
	}

	.desc-time {
		display: inline-block;
		margin-right: 20px;
		padding-bottom: 10px;
	}

	.desc-cms {
		color: #4185bb;
		background: #f1f5cd;
		padding: 10px;
		border: dashed 1px #acd2f1;
		margin: 15px 0 10px;
		position: relative;
	}

	.desc-content {
		margin-top: 20px;
		background: #F8F8F8;
		padding: 20px;
		border: 1px solid #eee;
	}
	
</style>
